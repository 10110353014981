// const SITE_BASE_URL = 'http://127.0.0.1:8000/api/admin/';
// const SITE_BASE_URL = 'http://127.0.0.1:8000/api/';
const SITE_BASE_URL = 'https://master.hitnish.com/api/';

export const PAGE_ROUTE = {
    ADMIN_DASHBOARD: '/admin/dashboard',

    ADMIN_CATEGORY_LIST: '/admin/category',
    ADMIN_CATEGORY_CREATE: '/admin/category/create',
    ADMIN_CATEGORY_EDIT: '/admin/category/edit/',

    ADMIN_SUB_CATEGORY_LIST: '/admin/sub-category',
    ADMIN_SUB_CATEGORY_CREATE: '/admin/sub-category/create',
    ADMIN_SUB_CATEGORY_EDIT: '/admin/sub-category/edit/',

    ADMIN_PRODUCT_LIST: '/admin/product',
    ADMIN_PRODUCT_CREATE: '/admin/product/create',
    ADMIN_PRODUCT_EDIT: '/admin/product/edit/',

    ADMIN_USERS_LIST: '/admin/users',
    ADMIN_USERS_CREATE: '/admin/users/create',
    ADMIN_USERS_EDIT: '/admin/users/edit/',

    ADMIN_BUSSINESS_LIST: '/admin/bussiness',
    ADMIN_BUSSINESS_CREATE: '/admin/bussiness/create',
    ADMIN_BUSSINESS_EDIT: '/admin/bussiness/edit/',

    ADMIN_SLIDER_LIST: '/admin/slider',
    ADMIN_SLIDER_CREATE: '/admin/slider/create',
    ADMIN_SLIDER_EDIT: '/admin/slider/edit/',
}

export const API_URL = {

    ADMIN_PARTY_LIST: `${SITE_BASE_URL}party`,
    ADMIN_PARTY_GET: `${SITE_BASE_URL}party/show`,
    ADMIN_PARTY_SAVE: `${SITE_BASE_URL}party/store`,
    ADMIN_PARTY_UPDATE: `${SITE_BASE_URL}party/update`,
    ADMIN_PARTY_DELETE: `${SITE_BASE_URL}party/delete`,

    ADMIN_GRADE_LIST: `${SITE_BASE_URL}grade`,
    ADMIN_GRADE_GET: `${SITE_BASE_URL}grade/show`,
    ADMIN_GRADE_SAVE: `${SITE_BASE_URL}grade/store`,
    ADMIN_GRADE_UPDATE: `${SITE_BASE_URL}grade/update`,
    ADMIN_GRADE_DELETE: `${SITE_BASE_URL}grade/delete`,

    ADMIN_SALES_LIST: `${SITE_BASE_URL}sales`,
    ADMIN_SALES_GET: `${SITE_BASE_URL}sales/show`,
    ADMIN_SALES_SAVE: `${SITE_BASE_URL}sales/store`,
    ADMIN_SALES_UPDATE: `${SITE_BASE_URL}sales/update`,
    ADMIN_SALES_DELETE: `${SITE_BASE_URL}sales/delete`,

    ADMIN_PURCHASE_LIST: `${SITE_BASE_URL}purchase`,
    ADMIN_PURCHASE_GET: `${SITE_BASE_URL}purchase/show`,
    ADMIN_PURCHASE_SAVE: `${SITE_BASE_URL}purchase/store`,
    ADMIN_PURCHASE_UPDATE: `${SITE_BASE_URL}purchase/update`,
    ADMIN_PURCHASE_DELETE: `${SITE_BASE_URL}purchase/delete`,

    ADMIN_PAYMENT_LIST: `${SITE_BASE_URL}payments`,
    ADMIN_PAYMENT_GET: `${SITE_BASE_URL}payments/show`,
    ADMIN_PAYMENT_SAVE: `${SITE_BASE_URL}payments/store`,
    ADMIN_PAYMENT_UPDATE: `${SITE_BASE_URL}payments/update`,
    ADMIN_PAYMENT_DELETE: `${SITE_BASE_URL}payments/delete`,

    ADMIN_LEDGER_LIST: `${SITE_BASE_URL}ledgers`,
    ADMIN_LEDGER_OUTSATANDING: `${SITE_BASE_URL}ledgers/outstanding`,


    ADMIN_SETTING_INQUIRIES: `${SITE_BASE_URL}setting/inquiries`,
    ADMIN_SETTING_SAVE: `${SITE_BASE_URL}setting/store`,
    ADMIN_SETTING_GET: `${SITE_BASE_URL}setting/show`,
    ADMIN_SETTING_PASSWORD: `${SITE_BASE_URL}setting/password`,
    ADMIN_ABOUT_US_SAVE: `${SITE_BASE_URL}setting/about`,
    LOGIN_URL: `${SITE_BASE_URL}login`,
    UPDATE_PASSWORD: `${SITE_BASE_URL}update-password`,

    ADMIN_PRODUCT_LIST: `${SITE_BASE_URL}product`,
    ADMIN_PRODUCT_ALL: `${SITE_BASE_URL}product/lists`,
    ADMIN_PRODUCT_GET: `${SITE_BASE_URL}product/show`,
    ADMIN_PRODUCT_SAVE: `${SITE_BASE_URL}product/store`,
    ADMIN_PRODUCT_UPDATE: `${SITE_BASE_URL}product/update`,
    ADMIN_PRODUCT_DELETE: `${SITE_BASE_URL}product/delete`,

    ADMIN_USERS_LIST: `${SITE_BASE_URL}users`,
    ADMIN_ALL_USERS_LIST: `${SITE_BASE_URL}users/all`,
    ADMIN_USERS_GET: `${SITE_BASE_URL}users/show?id=`,
    ADMIN_USERS_SAVE: `${SITE_BASE_URL}users/store`,
    ADMIN_USERS_UPDATE: `${SITE_BASE_URL}users/update`,
    ADMIN_USERS_DELETE: `${SITE_BASE_URL}users/delete?id=`,

    ADMIN_BUSSINESS_LIST: `${SITE_BASE_URL}business`,
    ADMIN_BUSSINESS_GET: `${SITE_BASE_URL}business/show?id=`,
    ADMIN_BUSSINESS_SAVE: `${SITE_BASE_URL}business/store`,
    ADMIN_BUSSINESS_UPDATE: `${SITE_BASE_URL}business/update`,
    ADMIN_BUSSINESS_DELETE: `${SITE_BASE_URL}business/delete?id=`,
}

