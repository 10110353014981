import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../shared/table';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';

export default function AddPayment() {

    const columns = [{
        field: 'name',
        lable: 'Name'
    }];

    const [Id, setId] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [addTextBox, showAddTextBox] = useState(false);
    const [gradeName, setGradeName] = useState('');
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    useEffect(() => {
        getGrade();
    }, []);

    useEffect(() => {
        isRecall && getGrade();
    }, [isRecall]);

    const getGrade = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_GRADE_LIST, {}).then(res => {
            setData(res.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        setLoading(true);
        axios.post(API_URL.ADMIN_GRADE_DELETE, { id: item.id }).then((response) => {
            setIsRecall(true);
            setLoading(false);
        });
    };

    const onEdit = (item) => {
        setId(item.id);
        setGradeName(item.name);
        showAddTextBox(true);
    };

    const clearData = (status) => {
        setGradeName('');
        showAddTextBox(status);
    } 

    const saveData = () => {
        setLoading(true);
        let payload = {
            name: gradeName,
            id: Id
        }
        axios.post((Id > 0) ? API_URL.ADMIN_GRADE_UPDATE: API_URL.ADMIN_GRADE_SAVE, payload).then((response) => {
            setIsRecall(true);
            setId(0);
            setLoading(false);
            showAddTextBox(false);
            setGradeName('');
        }, error => {
            setLoading(false);
        });
    };

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    Grades
                    <button type='button' className='btn btn-primary float-end' onClick={() => clearData(true)}>Add</button>
                </div>
                <div className='card-body'>
                    { addTextBox && <div className='row p-3'>
                        <div className='col-12'>
                            <input type={'text'} className="form-control input-sm" value={gradeName} placeholder='Name' onChange={(e) => setGradeName(e.target.value)} />
                        </div>
                        <div className='col-12 mt-3'>
                            <button type='button' className='btn btn-secondary' onClick={() => clearData(false)}>Cancel</button>
                            <button type='button' className='btn btn-dark float-end' disabled={!gradeName} onClick={() => saveData()}>Save</button>
                        </div>
                    </div> }

                    {
                        isLoading && <LoaderComponent mode='full' height={100} width={100}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}