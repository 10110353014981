import { React, useEffect, useState } from 'react';
import { API_URL } from '../../shared/constant';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import * as moment from 'moment';
import swal from 'sweetalert';
import { FaEdit, FaTrash } from 'react-icons/fa';

export default function SearchPayment() {

    const [isLoading, setLoading] = useState(false);
    const [addTextBox, showAddTextBox] = useState(false);
    const [partyData, setPartyData] = useState([]);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [isRecall, setIsRecall] = useState(false);
    const [formData, setFormData] = useState({
        id: 0,
        party_id: '',
        payment_mode: '',
        payment_type: '',
        date: new Date(),
        amount: 0,
        remark: ''
    });
    const paymentTypes = [{
        label: "Received",
        value: "Received",
    }, {
        label: "Paid",
        value: "Paid",
    }];
    const paymentModes = [{
        label: "Cash",
        value: "Cash",
    }, {
        label: "Bank",
        value: "Bank",
    }];
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let Id = params.get('id');

    useEffect(() => {
        getParty();
        getPayments();
    }, []);

    useEffect(() => {
        if (Id && Id > 0) {
            axios.post(API_URL.ADMIN_PAYMENT_GET, {id : Id}).then(res => {
                if (res.data.status == 1) {
                    onEdit(res.data.data);
                } else {
                    toast.error(res.data.data);
                }
            });
        }
    }, [Id])

    useEffect(() => {
        isRecall && getPayments();
    }, [isRecall]);

    const getParty = () => {
        axios.post(API_URL.ADMIN_PARTY_LIST, { is_seller : [0, 1]}).then(res => {
            let pt = res.data.data.map((row) => {
                return {
                    value: row.id,
                    label: row.name
                }
            })
            setPartyData(pt);
        })
    };

    const getPayments = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PAYMENT_LIST, {}).then(res => {
            setData(res.data.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PAYMENT_DELETE, { id: item.id }).then((response) => {
            setIsRecall(true);
            setLoading(false);
        });
    };

    const onEdit = (item) => {
        setFormData({
            id: item.id,
            party_id: item.party_id,
            payment_mode: item.payment_mode,
            payment_type: item.payment_type,
            amount: item.amount,
            date: item.data,
            remark: item.remark ? item.remark: ""
        });
        setStartDate(new Date(item.date));
        showAddTextBox(true);
    };

    const clearData = (status) => {
        setFormData({
            id: 0,
            party_id: '',
            payment_mode: '',
            payment_type: '',
            date: new Date(),
            amount: 0,
            remark: ''
        });
        showAddTextBox(status);
    } 

    const saveData = () => {
        setLoading(true);
        let body = {...formData}
        body.date = moment(startDate).format("YYYY-MM-DD");
        axios.post((formData.id > 0) ? API_URL.ADMIN_PAYMENT_UPDATE: API_URL.ADMIN_PAYMENT_SAVE, body).then((response) => {
            if (response.data.status == 1) {
                setIsRecall(true);
                clearData(false);
                toast.success("Payment details added.");
            } else {
                toast.error(response.data.data);
            }

            setLoading(false);
        }, error => {
            setLoading(false);
        });
    };

    const onDeleteClick = (item) => {
        swal({
            title: "Are you sure want to delete?",
            icon: "warning",
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            buttons: true
        }).then((willDelete) => {
            if (willDelete) {
                onDelete && onDelete(item);
            }
        });
    }

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <span className='pt-2 d-inline-block'>Payments</span>
                    <button type='button' className='btn btn-primary float-end' onClick={() => clearData(true)}>Add</button>
                </div>
                <div className='card-body p-0'>
                    { addTextBox && <div className='row p-3'>
                        <div className='col-12'>
                            <Select placeholder={'Select Party'} options={partyData} value={partyData.find(pt => pt.value == formData.party_id)} onChange={(e) => setFormData({...formData, party_id: e.value})} />
                        </div>
                        <div className='col-6 mt-3'>
                            <Select placeholder={'Type'} options={paymentTypes} value={paymentTypes.find(pt => pt.value == formData.payment_type)} onChange={(e) => setFormData({...formData, payment_type: e.value})} />
                        </div>
                        <div className='col-6 mt-3'>
                            <Select placeholder={'Mode'} options={paymentModes} value={paymentModes.find(pt => pt.value == formData.payment_mode)} onChange={(e) => setFormData({...formData, payment_mode: e.value})} />
                        </div>
                        <div className='col-6 mt-3'>
                            <DatePicker dateFormat="dd/MM/yyyy" className="form-control input-sm" selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <div className='col-6 mt-3'>
                            <input type={'number'} onWheel={(e) => e.target.blur()} className="form-control input-sm" value={formData.amount} placeholder='Amount' onChange={(e) => setFormData({...formData, amount: e.target.value})} />
                        </div>
                        <div className='col-12 mt-3'>
                            <input type={'text'} className="form-control input-sm" value={formData.remark} placeholder='Remark' onChange={(e) => setFormData({...formData, remark: e.target.value})} />
                        </div>
                        <div className='col-12 mt-3'>
                            <button type='button' className='btn btn-secondary' onClick={() => clearData(false)}>Cancel</button>
                            <button type='button' className='btn btn-dark float-end' disabled={((!formData.party_id || formData.party_id == 0 || !formData.payment_mode || !formData.payment_type || !formData.amount || formData.amount == 0 || !startDate))} onClick={() => saveData()}>{ formData.id > 0 ? 'Update': 'Save' }</button>
                        </div>
                    </div> }
                    {
                        !isLoading && <div className="table-responsive">
                            {
                            (data && data.length === 0) &&
                                <div className='no-data-found-text'>
                                    No Data Found.
                                </div>
                            }
                            {
                                (data && data.length > 0) &&
                                <table className='table table-hover m-0'>
                                    <thead className='thead'>
                                        <tr>
                                            <th>Date</th>
                                            <th>Party</th>
                                            <th className='text-end'>Amount</th>
                                            <th width="100" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((element, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            {element.payment_date}
                                                            <div>
                                                                <strong className={element.payment_type == 'Paid' ? 'text-success': 'text-danger'}>
                                                                    {element.payment_view == 'Purchase' ? 'Purchased' : ''}
                                                                    {element.payment_view == 'Sales' ? 'Sales' : ''}
                                                                    {element.payment_view == 'Direct' ? element.payment_type : ''}
                                                                    {element.is_opening == 1 ? ' - Opening' : ''}
                                                                </strong>
                                                            </div>
                                                        </td>
                                                        <td>{element.party_name}</td>
                                                        <td className='text-end'>{element.amount}</td>
                                                        <td className='text-center'>
                                                            { (element.payment_view == 'Direct' && element.is_opening == 0) ? <>
                                                                <button type="button" className="btn btn-outline-primary btn-sm me-2" onClick={() => onEdit(element)}><FaEdit /></button>
                                                                <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => onDeleteClick(element)}><FaTrash /></button>
                                                            </>: null }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                    }
                </div>
            </div>
            {
                isLoading && <LoaderComponent mode={'full'} height={100} width={100}></LoaderComponent>
            }
        </div>
    )
}