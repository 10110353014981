import { React, useEffect, useState } from 'react';
import {  API_URL } from '../../shared/constant';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as moment from 'moment';

export default function AddPurchase() {

    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [isLoading, setLoading] = useState(false);
    const [partyData, setPartyData] = useState([]);
    const [products, setProducts] = useState([]);
    const [grades, setGrades] = useState([]);
    const [formData, setFormData] = useState({
        id: 0,
        party_id: '',
        date: new Date(),
        invoice_no: '',
        sub_total: 0,
        tax_amount: 0,
        cash_amount: 0,
        bill_amount: 0,
        total_amount: 0,
        remark: '',
    });
    const [parameters, setParameters] = useState([{
        id: 0,
        qty: 0,
        rate: 0,
        amount: 0,
        grade_id: 0,
        series: '',
        product_id: 0,
        sub_product_id: 0,
    }])
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let Id = params.get('id');

    useEffect(() => {
        getParty();
        getProducts();
        getGrades();
        if (Id && Id > 0) {
            getEdit()
        }
    }, [Id]);

    const getEdit = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PURCHASE_GET, { id : Id}).then(res => {
            if (res.data.status == 1) {
                setFormData({
                    id: res.data.data.id,
                    party_id: res.data.data.party_id,
                    date: res.data.data.date,
                    invoice_no: (res.data.data.invoice_no) ? res.data.data.invoice_no: '',
                    sub_total: res.data.data.sub_total,
                    tax_amount: res.data.data.tax_amount,
                    cash_amount: res.data.data.cash_amount,
                    bill_amount: res.data.data.bill_amount,
                    total_amount: res.data.data.total_amount,
                    remark: (res.data.data.remark) ? res.data.data.remark: ""
                });
                setStartDate(new Date(res.data.data.date));
                let parItm = res.data.data.items.map((r) => {
                    return {
                        id: r.product_id + '-' + r.sub_product_id,
                        qty: r.qty,
                        rate: r.rate,
                        amount: r.amount,
                        grade_id: r.grade_id,
                        series: r.series,
                        product_id: r.product_id,
                        sub_product_id: r.sub_product_id,
                    }
                })
                setParameters(parItm);
            } else {
                toast.error(res.data.data);
            }
            setLoading(false);
        })
    };
    
    const getParty = () => {
        axios.post(API_URL.ADMIN_PARTY_LIST, { is_seller: [0] }).then(res => {
            let pt = res.data.data.map((row) => {
                return {
                    value: row.id,
                    label: row.name
                }
            })
            setPartyData(pt);
        })
    };
    
    const getGrades = () => {
        axios.post(API_URL.ADMIN_GRADE_LIST, {}).then(res => {
            let pt = res.data.data.map((row) => {
                return {
                    value: row.id,
                    label: row.name
                }
            })
            setGrades(pt);
        })
    };
    
    const getProducts = () => {
        axios.post(API_URL.ADMIN_PRODUCT_ALL, {}).then(res => {
            let prds = res.data.data.map((row) => {
                return {...row, value: row.id, label: row.name}
            })
            setProducts(prds);
        })
    };

    const saveData = () => {
        setLoading(true);
        let body = {...formData}
        body.items = parameters;
        body.date = moment(startDate).format("YYYY-MM-DD");
        axios.post((formData.id > 0) ? API_URL.ADMIN_PURCHASE_UPDATE: API_URL.ADMIN_PURCHASE_SAVE, body).then((response) => {
            if (response.data.status == 1) {
                toast.success("Purchase details added.");
                navigate('/admin/purchase');
            } else {
                toast.error(response.data.data);
            }
            setLoading(false);
        }, error => {
            setLoading(false);
        });
    };

    const addParameter = () => {
        let pr = [...parameters]
        pr.push({
            id: 0,
            qty: 0,
            rate: 0,
            amount: 0,
            grade_id: 0,
            series: '',
            product_id: 0,
            sub_product_id: 0
        })
        setParameters(pr)
    }

    const removeParameter = (i) => {
        let pr = [...parameters]
        pr.splice(i, 1)
        setParameters(pr)
    }

    const updateValue = (i, name, value) => {
        let pr = [...parameters]
        if (pr[i]) {
            if (name == 'id') { 
                pr[i]['id'] = value.value;
                pr[i]['product_id'] = value.product_id;
                pr[i]['sub_product_id'] = value.sub_product_id;
            } else {
                pr[i][name] = value;
            }
            if (pr[i].qty && pr[i].rate) {
                pr[i]['amount'] = parseInt(pr[i].qty * pr[i].rate);
            }
        }
        setParameters(pr)
        calc(pr, formData.tax_amount, formData.bill_amount)
    }

    const updateAmounts = async(name, value) => {
        let tax_amount =formData.tax_amount;
        let bill_amount =formData.bill_amount;
        if (name == 'tax_amount') {
            tax_amount = value;
        } else {
            bill_amount = value;
        }
        calc(parameters, tax_amount, bill_amount);
    }

    const calc = (params, taxAmount, billAmount) => {
        let sub_total = 0;
        let total_amount = 0;
        let tax_amount = (taxAmount) ? parseInt(taxAmount): 0;
        let bill_amount = (billAmount) ? parseInt(billAmount): 0;
        params.map((prm) => {
            if (prm.qty && prm.rate) {
                sub_total += prm.qty * prm.rate;
            }
        });
        total_amount = sub_total + tax_amount;
        let cash_amount = total_amount - bill_amount;
        setFormData({...formData, sub_total: parseInt(sub_total), total_amount: parseInt(total_amount), tax_amount: parseInt(tax_amount), bill_amount: parseInt(bill_amount), cash_amount: parseInt(cash_amount)});
    }

    return (
        <div>
            { isLoading && <LoaderComponent mode={'full'} color={'white'} style={{ textAlign: 'center' }} height={80} width={80}></LoaderComponent> }
            <div className='card'>
                <div className='card-header'>
                    Add Purchase
                </div>
                <div className='card-body'>
                    <div className='row pb-3'>
                        <div className='col-12'>
                            <Select placeholder={'Select Party'} options={partyData} value={partyData.find(pt => pt.value == formData.party_id)} onChange={(e) => setFormData({...formData, party_id: e.value})} />
                        </div>
                        <div className='col-6 mt-3'>
                            <DatePicker dateFormat="dd/MM/yyyy" className="form-control input-sm" selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <div className='col-6 mt-3'>
                            <input type={'text'} className="form-control input-sm" value={formData.invoice_no} placeholder='Invoice No' onChange={(e) => setFormData({...formData, invoice_no: e.target.value})} />
                        </div>
                    </div>
                    <div className='py-4 border-top'>
                        <div className='d-flex align-items-center'>
                            <div style={{width: '70%'}}>Products</div>
                            <button style={{width: '30%'}} type='button' className='btn btn-dark align-items-end' onClick={() => addParameter()}>Add</button>
                        </div>
                        {
                            parameters.map((item, i) => {
                               return <div key={i} className='row mt-4 pt-4 border-top position-relative'>
                                    <div className='col-12'>
                                        <Select options={products} value={products.find(pt => pt.value == item.id)} placeholder={'Select Product'} onChange={(event) => { updateValue(i, 'id', event) }} />
                                    </div>
                                    <div className='col-6 mt-3'>
                                        <Select options={grades} value={grades.find(pt => pt.value == item.grade_id)} placeholder={'Grade'} onChange={(event) => { updateValue(i, 'grade_id', event.value) }} />
                                    </div>
                                    <div className='col-6 mt-3'>
                                        <input type={'text'} className="form-control input-sm" value={item.series} placeholder='Item' onChange={(event) => { updateValue(i, 'series', event.target.value) }} />
                                    </div>
                                    <div className='col-4 mt-3'>
                                        <div className="form-floating">
                                            <input type={'number'} onWheel={(e) => e.target.blur()} className="form-control input-sm" value={item.qty} placeholder='Qty' onChange={(event) => { updateValue(i, 'qty', event.target.value) }} />
                                            <label>Qty</label>
                                        </div>
                                    </div>
                                    <div className='col-4 mt-3'>
                                        <div className="form-floating">
                                            <input type={'number'} onWheel={(e) => e.target.blur()} className="form-control input-sm" value={item.rate} placeholder='Rate' onChange={(event) => { updateValue(i, 'rate', event.target.value) }} />
                                            <label>Rate</label>
                                        </div>
                                    </div>
                                    <div className='col-4 mt-3'>
                                        <div className="form-floating">
                                            <input disabled type={'number'} onWheel={(e) => e.target.blur()} className="form-control input-sm" value={item.amount} placeholder='Amount' />
                                            <label>Amount</label>
                                        </div>
                                    </div>
                                    <button tabIndex={'-1'} className="btn btn-danger remove-icons" type='button' onClick={() => removeParameter(i)}><FaTimes /></button>
                                </div>
                            })
                        }
                    </div>  
                    <div className='row pb-3 border-top border-bottom'>
                        <div className='col-6 mt-3'>
                            <div className="form-floating">
                                <input disabled type={'number'} onWheel={(e) => e.target.blur()} className="form-control input-sm" value={formData.sub_total} placeholder='Sub Total' />
                                <label>Sub Total</label>
                            </div>
                        </div>
                        <div className='col-6 mt-3'>
                            <div className="form-floating">
                                <input type={'number'} onWheel={(e) => e.target.blur()} className="form-control input-sm" value={formData.tax_amount} placeholder='Total Tax' onChange={(e) => updateAmounts('tax_amount', e.target.value)} />
                                <label>Total Tax</label>
                            </div>
                        </div>
                        <div className='col-6 mt-3'>
                            <div className="form-floating">
                                <input type={'number'} onWheel={(e) => e.target.blur()}className="form-control input-sm" value={formData.bill_amount} placeholder='Bill Amount' onChange={(e) => updateAmounts('bill_amount', e.target.value)} />
                                <label>Bill Amount</label>
                            </div>
                        </div>
                        <div className='col-6 mt-3'>
                            <div className="form-floating">
                                <input disabled type={'number'} onWheel={(e) => e.target.blur()}className="form-control input-sm" value={formData.cash_amount} placeholder='Cash Amount' />
                                <label>Cash Amount</label>
                            </div>
                        </div>
                        <div className='col-12 mt-3'>
                            <div className="form-floating">
                            <input disabled type={'number'} onWheel={(e) => e.target.blur()} className="form-control input-sm" value={formData.total_amount} placeholder='Total Amount' />
                                <label>Total Amount</label>
                            </div>
                        </div>
                        <div className='col-12 mt-3'>
                            <div className="form-floating">
                                <input type={'text'} className="form-control input-sm" value={formData.remark} placeholder='Remark' onChange={(e) => setFormData({...formData, remark: e.target.value})} />
                                <label>Remark</label>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 mt-3'>
                        <Link className='btn btn-primary' to={'/admin/purchase'}>Cancel</Link>
                        <button type='button' className='btn btn-dark float-end' disabled={!formData.party_id || !parameters.length} onClick={() => saveData()}>{ formData.id > 0 ? 'Update': 'Save' }</button>
                    </div>
                </div>
            </div>
        </div>
    )
}