import { React, useEffect, useState, useRef } from 'react';
import { API_URL } from '../../shared/constant';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../shared/table';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';


export default function SearchParty() {

    const columns = [{
        field: 'name',
        lable: 'Name'
    },{
        field: 'party_type',
        lable: 'Type'
    }];
    
    const partyData = [{
        value: 1,
        label: 'For Sales'
    }, {
        value: 0,
        label: 'For Purchase'
    }];

    const selectRef = useRef();
    const [Id, setId] = useState(0);
    const [isSeller, setIsSeller] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [addTextBox, showAddTextBox] = useState(false);
    const [partyName, setPartyName] = useState('');
    const [cashAmount, setCashAmount] = useState(null);
    const [billAmount, setBillAmount] = useState(null);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    useEffect(() => {
        getParty();
    }, []);

    useEffect(() => {
        isRecall && getParty();
    }, [isRecall]);

    const getParty = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PARTY_LIST, { is_seller : [0, 1]}).then(res => {
            setData(res.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PARTY_DELETE, { id: item.id }).then((response) => {
            if (response.data.status == 1) {
                setIsRecall(true);
            } else {
                toast.error(response.data.data);
            }
            setLoading(false);
        });
    };

    const onEdit = (item) => {
        setId(item.id);
        setPartyName(item.name);
        if (item.cash_amount) {
            setCashAmount(item.cash_amount);
        } else {
            setCashAmount("");
        }
        if (item.bill_amount) {
            setBillAmount(item.bill_amount);
        } else {
            setBillAmount("");
        }
        setIsSeller(item.is_seller)
        showAddTextBox(true);
    };

    const clearData = (status) => {
        setPartyName('');
        setCashAmount("");
        setBillAmount("");
        showAddTextBox(status);
        if (selectRef && selectRef.current) {
            selectRef.current.clearValue();
        }
    } 
    
    const changePartyType = (e) => {
        if (e) {
            setIsSeller(e.value);
        } else {
            setIsSeller(null);
        }
    } 

    const saveData = () => {
        if (!partyName) {
            toast.error("Please eneter party name");
            return;
        }
        if (isSeller != 0 && isSeller != 1) {
            toast.error("Please select party type");
            return;

        }
        setLoading(true);
        let payload = {
            name: partyName,
            id: Id,
            is_seller: isSeller,
            cash_amount: cashAmount,
            bill_amount: billAmount
        }
        axios.post((Id > 0) ? API_URL.ADMIN_PARTY_UPDATE: API_URL.ADMIN_PARTY_SAVE, payload).then((response) => {
            setIsRecall(true);
            setId(0);
            setLoading(false);
            showAddTextBox(false);
            setPartyName('');
        }, error => {
            setLoading(false);
        });
    };

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <span className='pt-2 d-inline-block'>Party</span>
                    <button type='button' className='btn btn-primary float-end' onClick={() => clearData(true)}>Add</button>
                </div>
                <div className='card-body p-0'>
                    
                    { addTextBox && <div className='row p-3'>
                        <div className='col-md-4 col-sm-12 mb-2'>
                            <Select ref={selectRef} placeholder={'Party Type'} value={partyData.find(pt => pt.value == isSeller)} options={partyData} onChange={(e) => changePartyType(e) } isClearable />
                        </div>
                        <div className='col-md-4 col-sm-12 mb-2'>
                            <input type={'text'} className="form-control input-sm" value={partyName} placeholder='Name' onChange={(e) => setPartyName(e.target.value)} />
                        </div>
                        <div className='col-md-2 col-sm-12 mb-2'>
                            <input type={'number'} onWheel={(e) => e.target.blur()} className="form-control input-sm" value={billAmount} placeholder='Bill Amount' onChange={(e) => setBillAmount(e.target.value)} />
                        </div>
                        <div className='col-md-2 col-sm-12 mb-2'>
                            <input type={'number'} onWheel={(e) => e.target.blur()} className="form-control input-sm" value={cashAmount} placeholder='Cash Amount' onChange={(e) => setCashAmount(e.target.value)} />
                        </div>
                        <div className='col-md-12 col-sm-12 mt-3'>
                            <button type='button' className='btn btn-secondary' onClick={() => clearData(false)}>Cancel</button>
                            <button type='button' className='btn btn-dark float-end' disabled={!partyName || (isSeller != 0 && isSeller != 1)} onClick={() => saveData()}>Save</button>
                        </div>
                    </div> }
                    {
                        !isLoading && <div className="table-responsive">
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                        </div>
                    }
                </div>
            </div>
            {
                isLoading && <LoaderComponent mode='full' height={100} width={100}></LoaderComponent>
            }
        </div>
    )
}