import { React, useEffect, useState, useRef } from 'react';
import { API_URL } from '../../shared/constant';
import LoaderComponent from '../../shared/loader';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import html2pdf from 'html2pdf.js';
import { FaEdit, FaTrash } from 'react-icons/fa';
import moment from 'moment';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

export default function SearchLedger() {

    const [showAction, setShowAction] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [partyId, setPartyId] = useState(0);
    const [data, setData] = useState([]);
    const [partyData, setPartyData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalQty, setTotalQty] = useState(0);
    const [debitBillAmonnt, setDebitBillAmonnt] = useState(0);
    const [debitCashAmount, setDebitCashAmount] = useState(0);
    const [debitTotal, setDebitTotal] = useState(0);
    const [creditBillAmonnt, setCreditBillAmonnt] = useState(0);
    const [creditCashAmount, setCreditCashAmount] = useState(0);
    const [creditTotal, setCreditTotal] = useState(0);
    const [totalCashAmount, setTotalCashAmount] = useState(0);
    const [totalBillAmount, setTotalBillAmount] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [openingAmount, setOpeningAmount] = useState({
        is_opening: false,
        balance_amount: 0,
        credit_bill_total: 0,
        credit_cash_total: 0,
        credit_total: 0,
        debit_bill_total: 0,
        debit_cash_total: 0,
        debit_total: 0
    });
    const reportTemplateRef = useRef(null);
    const selectRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        getParty();
    }, []);

    const downloadPdf = () => {
        setLoading(true);
        setShowAction(false);
        var element = document.getElementById('element-to-print');
        const pDetail = partyData.find(pt => pt.value == partyId);
        setTimeout(() => {
            var opt = {
                margin: 0.1,
                filename: pDetail.label + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 4 },
                pagebreak: { mode: 'avoid-all' },
                jsPDF: { unit: 'in', format: 'a3', orientation: 'landscape' }
            };
            html2pdf().set(opt).from(element).save().then(res => {
                setLoading(false);
            });
            setShowAction(true);
        }, 200);
    }

    const clearFilter = () => {
        setLoading(true);
        setStartDate(null);
        setEndDate(null);
        setTimeout(() => {
            selectRef.current.clearValue();
        }, 200);
    }

    const getParty = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PARTY_LIST, { is_seller : [0, 1]}).then(res => {
            let pt = res.data.data.map((row) => {
                return {
                    value: row.id,
                    label: row.name
                }
            })
            setPartyData(pt);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const changeParty = (party_id, from_date, to_date) => {
        setPartyId(party_id);
        setLoading(true);
        let params = {
            party_id: party_id,
            from_date: (from_date) ? moment(from_date).format("YYYY-MM-DD"): null,
            to_date: (to_date) ? moment(to_date).format("YYYY-MM-DD"): null,
        }
        axios.post(API_URL.ADMIN_LEDGER_LIST, params).then(res => {
            setData(res.data.data);
            setTotalQty(res.data.total_qty);
            setDebitBillAmonnt(res.data.debit_bill_total);
            setDebitCashAmount(res.data.debit_cash_total);
            setDebitTotal(res.data.debit_total);
            setCreditBillAmonnt(res.data.credit_bill_total);
            setCreditCashAmount(res.data.credit_cash_total);
            setCreditTotal(res.data.credit_total);
            setFinalAmount(res.data.finalAmount);
            let tcash = res.data.debit_cash_total - res.data.credit_cash_total;
            let tbill = res.data.debit_bill_total - res.data.credit_bill_total;
            setTotalCashAmount(tcash);
            setTotalBillAmount(tbill);
            setOpeningAmount(res.data.opening);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const searchDataByDate = (fromDate, toDate) => {
        if (fromDate && toDate) {
            changeParty(partyId, fromDate, toDate);
        }
    }

    const goToEdit = (row) => {
        if (row.payment_view == 'Purchase') {
            navigate('/admin/purchase/add?id=' + row.payment_view_id);
        } else if (row.payment_view == 'Sales') {
            navigate('/admin/sales/add?id=' + row.payment_view_id);
        } else {
            navigate('/admin/payments?id=' + row.id);
        }
    }

    const goToDelete = (row) => {
        swal({
            title: "Are you sure want to delete?",
            icon: "warning",
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            buttons: true
        }).then((willDelete) => {
            if (willDelete) {
                if (row.payment_view == 'Purchase') {
                    onDelete(row.payment_view_id, API_URL.ADMIN_PURCHASE_DELETE);
                } else if (row.payment_view == 'Sales') {
                    onDelete(row.payment_view_id, API_URL.ADMIN_SALES_DELETE);
                } else {
                    onDelete(row.id, API_URL.ADMIN_PAYMENT_DELETE);
                }
            }
        });
    }

    const onDelete = (id, url) => {
        setLoading(true);
        axios.post(url, { id: id }).then((response) => {
            if (response.data.status == 1) {
                changeParty(partyId, startDate, endDate);
            } else {
                toast.error(response.data.data);
            }
            setLoading(false);
        });
    };

    return (
        <div>
            <div className='card payments'>
                <div className='card-header'>
                    <span className='pt-1 d-inline-block'>Ledger</span>
                    <button type='button' disabled={(!partyId || !data.length)} className='btn btn-dark btn-sm float-end' onClick={() => downloadPdf()}>Download</button>
                </div>
                <div className='card-body'>
                    <div className="fz14">
                        <div className='row mb-3'>
                            <div className='col-md-3 mb-3 col-sm-12'>
                                <Select ref={selectRef} placeholder={'Select Party'} value={partyData.find(pt => pt.value == partyId)} options={partyData} onChange={(e) => changeParty((e ? e.value: 0), startDate, endDate)} />
                            </div>
                            <div className='col-md-3 mb-3 col-sm-12'>
                                <DatePicker showIcon placeholderText='From Date' dateFormat="dd/MM/yyyy" className="form-control input-sm" selected={startDate} onChange={(date) => [setStartDate(date), searchDataByDate(date, endDate)]} />
                            </div>
                            <div className='col-md-3 mb-3 col-sm-12'>
                                <DatePicker showIcon placeholderText='To Date' dateFormat="dd/MM/yyyy" className="form-control input-sm" selected={endDate} onChange={(date) => [setEndDate(date), searchDataByDate(startDate, date)]} />
                            </div>
                            <div className='col-md-3 col-sm-12 text-end'>
                            { (partyId  || startDate || endDate) && <button type='button' className='btn btn-dark' onClick={clearFilter}>Clear Filter</button> }
                        </div>
                        </div>
                        { (data.length > 0) ? 
                            <div className="table-responsive" ref={reportTemplateRef} id="element-to-print">
                                <table className='table table-hover table-bordered m-0'>
                                    <tbody>
                                        <tr>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} colSpan={3}>
                                                { (partyId && partyId > 0) ? (partyData.find(pt => pt.value == partyId).label) : "" }
                                            </th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-center" colSpan={3}>Debit</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-center" colSpan={3}>Credit</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end" colSpan={1}>Total</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end" colSpan={1}></th>
                                            { showAction && <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end hidden-print" colSpan={1}></th> }
                                        </tr>
                                        <tr>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px', minWidth: 73}}>Date</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}}>Particulars</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">Qty</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">Bill</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">Cash</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">Total</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">Bill</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">Cash</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">Total</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">Balance</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-center">Days</th>
                                            { showAction && <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px', width: 90}} className="text-end hidden-print">Action</th> }
                                        </tr>
                                    {openingAmount.is_opening &&
                                        <tr>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end' colSpan={3}>Opening Balance - ({ moment(startDate).format("DD/MM/YYYY") } to { moment(endDate).format("DD/MM/YYYY") })</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-primary'>{openingAmount.debit_bill_total.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-danger'>{openingAmount.debit_cash_total.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end'>{openingAmount.debit_total.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-primary'>{openingAmount.credit_bill_total.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-danger'>{openingAmount.credit_cash_total.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end'>{openingAmount.credit_total.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end'>{ (Math.abs(openingAmount.balance_amount)).toFixed(2) } { (openingAmount.balance_amount > 0) ? 'Cr': 'Dr' }</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-center'></th>
                                            { showAction && <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end hidden-print"></th> }
                                        </tr>
                                    }
                                        {
                                            data.map((elem, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}}>{elem.date}</td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}}>
                                                            <div style={{minHeight: 30}}>{elem.invoice_no}<span>{ (elem.invoice_no && elem.remark) ? ' - ': ''} {elem.remark}</span>{(elem.is_opening == 1) ? <span>Opening</span> : ""}</div>
                                                            { (elem.items && elem.items.length) ? <div>
                                                                    <table className='table table-bordered m-0'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{verticalAlign: 'top', fontSize: 14, padding: '0px 5px 12px'}}>Name</th>
                                                                                <th style={{verticalAlign: 'top', fontSize: 14, padding: '0px 5px 12px'}}>Item</th>
                                                                                <th style={{verticalAlign: 'top', fontSize: 14, padding: '0px 5px 12px'}}>Grade</th>
                                                                                <th style={{verticalAlign: 'top', fontSize: 14, padding: '0px 5px 12px'}}>Qty</th>
                                                                                <th style={{verticalAlign: 'top', fontSize: 14, padding: '0px 5px 12px'}}>Rate</th>
                                                                                <th style={{verticalAlign: 'top', fontSize: 14, padding: '0px 5px 12px'}}>Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            { 
                                                                                elem.items.map((irm, k) => {
                                                                                    return (
                                                                                        <tr key={k + '-' + key}>
                                                                                            <td style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}}>{irm.product_name}</td>
                                                                                            <td style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}}>{irm.series}</td>
                                                                                            <td style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}}>{irm.grade_name}</td>
                                                                                            <td style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}}>{irm.qty}</td>
                                                                                            <td style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}}>{irm.rate}</td>
                                                                                            <td style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}}>{irm.amount}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <tr>
                                                                                <th style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}} className='text-end'>Total</th>
                                                                                <th style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}} colSpan={3}>Tax : {elem.tax_amount}</th>
                                                                                <th style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}}>{elem.sub_total}</th>
                                                                                <th style={{verticalAlign: 'top', fontSize: 12, padding: '0px 5px 12px'}}>{elem.amount}</th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div> : null
                                                            }
                                                        </td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">{elem.qty}</td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end text-primary">{elem.dr_bill.toFixed(2)}</td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end text-danger">{elem.dr_cash.toFixed(2)}</td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">{elem.dr_total.toFixed(2)}</td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end text-primary">{elem.cr_bill.toFixed(2)}</td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end text-danger">{elem.cr_cash.toFixed(2)}</td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end">{elem.cr_total.toFixed(2)}</td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className={(elem.balance_amount > 0) ? 'text-end text-success': 'text-end'}>{ (Math.abs(elem.balance_amount)).toFixed(2) } { (elem.balance_amount > 0) ? 'Cr': 'Dr' }</td>
                                                        <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-center">{moment().diff(moment(elem.date, "DD/MM/YYYY"), 'days')}</td>
                                                        { showAction && <td style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end hidden-print">
                                                            <button type="button" onClick={() => goToEdit(elem)} className="btn btn-outline-primary btn-sm me-2" style={{padding: '0px 4px'}}><FaEdit /></button>
                                                            <button type="button" onClick={() => goToDelete(elem)} className="btn btn-outline-danger btn-sm me-2" style={{padding: '0px 4px'}}><FaTrash /></button>
                                                        </td> }
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end' colSpan={2}>Total</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end'>{totalQty}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-primary'>{debitBillAmonnt.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-danger'>{debitCashAmount.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end'>{debitTotal.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-primary'>{creditBillAmonnt.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-danger'>{creditCashAmount.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end'>{creditTotal.toFixed(2)}</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end'>{ (Math.abs(finalAmount)).toFixed(2) } { (finalAmount > 0) ? 'Cr': 'Dr' }</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-center'></th>
                                            { showAction && <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end hidden-print"></th> }
                                        </tr>
                                        <tr>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end' colSpan={9}>Cash Amount</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-danger' colSpan={2}>{ (Math.abs(totalCashAmount)).toFixed(2) } { (totalCashAmount > 0) ? 'Dr': 'Cr' }</th>
                                            { showAction && <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end hidden-print"></th> }
                                        </tr>
                                        <tr>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end' colSpan={9}>Bill Amount</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end text-primary' colSpan={2}>{ (Math.abs(totalBillAmount)).toFixed(2) } { (totalBillAmount > 0) ? 'Dr': 'Cr' }</th>
                                            { showAction && <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end hidden-print"></th> }
                                        </tr>
                                        <tr>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end' colSpan={9}>Final Amount</th>
                                            <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className='text-end' colSpan={2}>{ (Math.abs(finalAmount)).toFixed(2) } { (finalAmount > 0) ? 'Cr': 'Dr' }</th>
                                            { showAction && <th style={{verticalAlign: 'top', fontSize: 16, padding: '0px 5px 12px'}} className="text-end hidden-print"></th> }
                                        </tr>
                                    </tbody>
                                </table>
                            </div> : null }
                    </div>
                </div>
            </div>
            {
                isLoading && <LoaderComponent mode='full' height={100} width={100}></LoaderComponent>
            }
        </div>
    )
}