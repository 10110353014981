import DashboardComponent from '../admin/dashboard/dashboard';
import SearchParty from '../admin/party/search';
import SearchGrade from '../admin/grade/search';
import SearchProduct from '../admin/product/search';
import SearchSales from '../admin/sales/search';
import SearchPurchase from '../admin/purchase/search';
import SearchPayment from '../admin/payments/search';
import SearchLedger from '../admin/ledger/search';
import SearchOutStanding from '../admin/outstanding/search';
import SearchLedgerByDay from '../admin/outstanding/searchLedgerByDay';
import ChangePassword from '../admin/setting/password';
import AddSales from '../admin/sales/add';
import AddPurchase from '../admin/purchase/add';
import AddPayment from '../admin/payments/add';


const AdminRoutes = [
    { path: '', component: <DashboardComponent /> },
    { path: 'dashboard', component: <DashboardComponent /> },
    { path: 'party', component: <SearchParty /> },
    { path: 'grade', component: <SearchGrade /> },
    { path: 'product', component: <SearchProduct /> },
    { path: 'sales', component: <SearchSales /> },
    { path: 'purchase', component: <SearchPurchase /> },
    { path: 'payments', component: <SearchPayment /> },
    { path: 'ledger', component: <SearchLedger /> },
    { path: 'ledger-day-wise', component: <SearchLedgerByDay /> },
    { path: 'outstanding', component: <SearchOutStanding /> },
    { path: 'change-password', component: <ChangePassword /> },
    { path: 'sales/add', component: <AddSales /> },
    { path: 'purchase/add', component: <AddPurchase /> },
    { path: 'payments/add', component: <AddPayment /> }
];

export default AdminRoutes;