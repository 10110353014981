import { React, useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { API_URL, PAGE_ROUTE } from '../../shared/constant';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './login.css';
import LoaderComponent from '../../shared/loader';

const LoginComponent = () => {

    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const validateForm = () => {
        return email.length > 0 && password.length > 0;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        axios.post(API_URL.LOGIN_URL, { email: email, password: password }).then(res => {
            if (res.data.status === 1) {
                sessionStorage.setItem('access_token', res.data.data.access_token);
                sessionStorage.setItem('company', res.data.data.user.name);
                navigate('/admin/ledger', { replace: true });
            } else {
                toast.error('Invalid Username or Password', { autoClose: 3000 })
            }
            setLoading(false);
        }, error => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        })
    }

    useEffect(() => {
        const access_token = sessionStorage.getItem('access_token');
        if (access_token) {
            navigate(PAGE_ROUTE.ADMIN_DASHBOARD, { replace: true });
        }
    }, [])

    return (
        <>
            <main className="main">
                <div className="container">
                    <section className="wrapper">
                        <div className="heading">
                            <h1 className="text text-large">Sign In</h1>
                            {/* <p className="text text-normal">New user? <span><a href="#" className="text text-links">Create an account</a></span></p> */}
                        </div>
                        <Form className="form"  name="signin">
                            <div className="input-control">
                                <label htmlFor="email" className="input-label" hidden>Email Address</label>
                                <Form.Control
                                    type="email"
                                    value={email}
                                    readOnly={readOnly}
                                    className="input-field"
                                    placeholder="Email Address"
                                    onFocus={() => setReadOnly(false)}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="input-control">
                                <label htmlFor="password" className="input-label" hidden>Password</label>
                                <Form.Control
                                    type="password"
                                    className="input-field"
                                    readOnly={readOnly}
                                    placeholder="Password"
                                    onFocus={() => setReadOnly(false)}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="input-control mb-0">
                                <a href="#" className="text text-links">Forgot Password</a>
                                <Button onClick={handleSubmit}
                                    type="submit"
                                    className='input-submit'
                                    disabled={!validateForm()}>
                                    Sign In
                                </Button>
                            </div>
                        </Form>
                    </section>
                </div>
            </main>
            {
                isLoading &&
                <LoaderComponent mode='full' height={100} width={100}></LoaderComponent>
            }
            <ToastContainer />
        </>
    )
}
export default LoginComponent;