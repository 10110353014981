import { React, useEffect, useState, useRef } from 'react';
import { PAGE_ROUTE, API_URL } from '../../shared/constant';
import { Link, useNavigate } from 'react-router-dom';
import TableComponent from '../../shared/table';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DatePicker from "react-datepicker";

export default function SearchPurchase() {

    const columns = [{
        field: 'date',
        lable: 'Date'
    }, {
        field: 'party_name',
        lable: 'Party'
    }, {
        field: 'total_amount',
        lable: 'Amount'
    }];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);
    const [partyData, setPartyData] = useState([]);
    const [partyId, setPartyId] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const selectRef = useRef();

    useEffect(() => {
        changeParty(0, null, null);
        getParty();
    }, []);

    useEffect(() => {
        isRecall && changeParty(partyId, startDate, endDate);
    }, [isRecall]);

    const getParty = () => {
        axios.post(API_URL.ADMIN_PARTY_LIST, { is_seller: [0] }).then(res => {
            let pt = res.data.data.map((row) => {
                return {
                    value: row.id,
                    label: row.name
                }
            })
            setPartyData(pt);
        }, error => {
        })
    };

    const clearFilter = () => {
        setLoading(true);
        setStartDate(null);
        setEndDate(null);
        setTimeout(() => {
            selectRef.current.clearValue();
        }, 200);
    }

    const changeParty = (party_id, from_date, to_date) => {
        setPartyId(party_id);
        setLoading(true);
        let params = {
            party_id: party_id,
            from_date: (from_date) ? moment(from_date).format("YYYY-MM-DD"): null,
            to_date: (to_date) ? moment(to_date).format("YYYY-MM-DD"): null,
        }
        axios.post(API_URL.ADMIN_PURCHASE_LIST, params).then(res => {
            setData(res.data.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    }

    const searchDataByDate = (fromDate, toDate) => {
        if (fromDate && toDate) {
            changeParty(partyId, fromDate, toDate);
        }
    }

    const onDelete = (item) => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PURCHASE_DELETE, { id: item.id }).then((response) => {
            if (response.data.status == 1) {
                setIsRecall(true);
                toast.success("Record deleted successfully");
            } else {
                toast.error(response.data.data);
            }
            setLoading(false);
        });
    };

    const onEdit = (item) => {
        navigate('/admin/purchase/add?id=' + item.id);
    };

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <span className='pt-2 d-inline-block'>Purchase</span>
                    <Link className='btn btn-primary float-end' to={'/admin/purchase/add'}>Add</Link>
                </div>
                <div className='card-body p-0'>
                    <div className='row my-3 mx-0'>
                        <div className='col-md-3 mb-3 col-sm-12'>
                            <Select ref={selectRef} placeholder={'Select Party'} value={partyData.find(pt => pt.value == partyId)} options={partyData} onChange={(e) => changeParty((e ? e.value : 0), startDate, endDate)} isClearable />
                        </div>
                        <div className='col-md-3 mb-3 col-sm-12'>
                            <DatePicker showIcon placeholderText='From Date' dateFormat="dd/MM/yyyy" className="form-control input-sm" selected={startDate} onChange={(date) => [setStartDate(date), searchDataByDate(date, endDate)]} />
                        </div>
                        <div className='col-md-3 mb-3 col-sm-12'>
                            <DatePicker showIcon placeholderText='To Date' dateFormat="dd/MM/yyyy" className="form-control input-sm" selected={endDate} onChange={(date) => [setEndDate(date), searchDataByDate(startDate, date)]} />
                        </div>
                        <div className='col-md-3 col-sm-12 text-end'>
                            { (partyId  || startDate || endDate) && <button type='button' className='btn btn-dark' onClick={clearFilter}>Clear Filter</button> }
                        </div>
                    </div>
                    {
                        !isLoading && <div className="table-responsive">
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                        </div>
                    }
                </div>
            </div>
            {
                isLoading && <LoaderComponent mode='full' height={100} width={100}></LoaderComponent>
            }
        </div>
    )
}