import { React, useEffect, useState } from 'react';
import { API_URL } from '../../shared/constant';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function ChangePassword() {

    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        password: '',
        password_confirmation: '',
        old_password: ''
    });

    useEffect(() => {
    }, []);

    const saveData = () => {

        if (!formData.old_password || !formData.password || !formData.password_confirmation) {
            toast.error("Please enter valid details");
            return;
        }

        if (formData.password !== formData.password_confirmation) {
            toast.error("Password confirmation does not match");   
            return;
        }

        setLoading(true);
        axios.post(API_URL.UPDATE_PASSWORD, formData).then((response) => {
            if (response.data.status == 1) {
                toast.success("Password Updated");   
                setFormData({
                    password: '',
                    password_confirmation: '',
                    old_password: ''
                });
            } else {
                toast.error(response.data.data);
            }
            setLoading(false);
        }, error => {
            setLoading(false);
            toast.error("Something went wrong");
        });
    };

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <span className=''>Change Password</span>
                </div>
                <div className='card-body p-0'>
                    <div className='row p-3'>
                        <div className='col-md-4 col-xs-12 mb-3'>
                            <div className="form-floating">
                                <input type={'text'} className="form-control input-sm" value={formData.old_password} placeholder='Old Password' onChange={(e) => setFormData({...formData, old_password: e.target.value})} />
                                <label>Old Password</label>
                            </div>
                        </div>
                        <div className='col-md-4 col-xs-12 mb-3'>
                            <div className="form-floating">
                                <input type={'text'} className="form-control input-sm" value={formData.password} placeholder='New Password' onChange={(e) => setFormData({...formData, password: e.target.value})} />
                                <label>New Password</label>
                            </div>
                        </div>
                        <div className='col-md-4 col-xs-12 mb-3'>
                            <div className="form-floating">
                                <input type={'text'} className="form-control input-sm" value={formData.password_confirmation} placeholder='Confirm Password' onChange={(e) => setFormData({...formData, password_confirmation: e.target.value})} />
                                <label>Confirm Password</label>
                            </div>
                        </div>
                        <div className='col-12'>
                            <button type='button' className='btn btn-dark float-end' onClick={() => saveData()}>Update Password</button>
                        </div>
                    </div>
                    {
                        isLoading && <LoaderComponent mode='full' height={100} width={100}></LoaderComponent>
                    }
                </div>
            </div>
        </div>
    )
}