import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
    const companyName = sessionStorage.getItem('company');
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const logout = () => {
        sessionStorage.clear();
        navigate('/login', { replace: true });
    }
    return (
        <Navbar bg="light" expand="lg" fixed="top" expanded={expanded}>
            <Container fluid>
                <Navbar.Brand>{companyName}</Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                        {/* <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/dashboard'}>Dashboard</Nav.Link> */}
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/party'}>Party</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/grade'}>Grade</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/product'}>Product</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/sales'}>Sales</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/purchase'}>Purchase</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/payments'}>Payments</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/ledger'}>Ledger</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/ledger-day-wise'}>Ledger By Day</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/outstanding'}>Outstanding</Nav.Link>
                        <Nav.Link onClick={() => setExpanded(false)} as={Link} to={'/admin/change-password'}>Change Password</Nav.Link>
                    </Nav>
                    <button type="button" onClick={logout} className="btn btn-danger btn-fw">Logout</button>
                </Navbar.Collapse>
            </Container>
            </Navbar>
    )
}

export default Header;