import { React, useEffect, useState } from 'react';
import { API_URL } from '../../shared/constant';
import TableComponent from '../../shared/table';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function SearchProduct() {

    const columns = [{
        field: 'name',
        lable: 'Name'
    }];

    const [isLoading, setLoading] = useState(false);
    const [addTextBox, showAddTextBox] = useState(false);
    const [partyData, setPartyData] = useState([]);
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        party_id: '',
        image: '',
        unit: 'Box'
    });

    useEffect(() => {
        getParty();
        getProducts();
    }, []);

    useEffect(() => {
        isRecall && getProducts();
    }, [isRecall]);

    const getParty = () => {
        axios.post(API_URL.ADMIN_PARTY_LIST, { is_seller : [0, 1]}).then(res => {
            let pt = res.data.data.map((row) => {
                return {
                    value: row.id,
                    label: row.name
                }
            })
            setPartyData(pt);
        })
    };

    const getProducts = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PRODUCT_LIST, {}).then(res => {
            setData(res.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PRODUCT_DELETE, { id: item.id }).then((response) => {
            if (response.data.status == 1) {
                setIsRecall(true);
            } else {
                toast.error(response.data.data);
            }
            setLoading(false);
        });
    };

    const onEdit = (item) => {
        setFormData({
            id: item.id,
            name: item.name,
            party_id: item.party_id,
            image: '',
            unit: item.unit
        });
        showAddTextBox(true);
    };

    const clearData = (status) => {
        setFormData({
            id: 0,
            name: '',
            party_id: 0,
            image: '',
            unit: 'Box'
        });
        showAddTextBox(status);
    } 

    const saveData = () => {
        setLoading(true);
        axios.post((formData.id > 0) ? API_URL.ADMIN_PRODUCT_UPDATE: API_URL.ADMIN_PRODUCT_SAVE, formData).then((response) => {
            if (response.data.status == 1) {
                setIsRecall(true);
                showAddTextBox(false);
                setFormData({
                    id: 0,
                    name: '',
                    party_id: 0,
                    image: '',
                    unit: ''
                });
            } else {
                toast.error(response.data.data);
            }

            setLoading(false);
        }, error => {
            setLoading(false);
        });
    };

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <span className='pt-2 d-inline-block'>Products</span>
                    <button type='button' className='btn btn-primary float-end' onClick={() => clearData(true)}>Add</button>
                </div>
                <div className='card-body p-0'>
                    { addTextBox && <div className='row p-3'>
                        {/* <div className='col-12'>
                            <Select placeholder={'Select Party'} options={partyData} value={partyData.find(pt => pt.value == formData.party_id)} onChange={(e) => setFormData({...formData, party_id: e.value})} />
                        </div> */}
                        <div className='col-8'>
                            <input type={'text'} className="form-control input-sm" value={formData.name} placeholder='Name' onChange={(e) => setFormData({...formData, name: e.target.value})} />
                        </div>
                        {/* <div className='col-4'>
                            <input type={'text'} className="form-control input-sm" value={formData.unit} placeholder='Unit' onChange={(e) => setFormData({...formData, unit: e.target.value})} />
                        </div> */}
                        <div className='col-8 mt-3'>
                            <button type='button' className='btn btn-secondary' onClick={() => clearData(false)}>Cancel</button>
                            <button type='button' className='btn btn-dark float-end' disabled={!formData.name} onClick={() => saveData()}>{ formData.id > 0 ? 'Update': 'Save' }</button>
                        </div>
                    </div> }

                    {
                        isLoading && <LoaderComponent mode='full' height={100} width={100}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}