import { React, useEffect, useState } from 'react';
import { API_URL } from '../../shared/constant';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import Form from 'react-bootstrap/Form';

export default function SearchOutStanding() {

    const [isLoading, setLoading] = useState(false);
    const [payType, setPayType] = useState('All');
    const [accountData, setAccountData] = useState([]);
    const [outstandingData, setOutstandingData] = useState({
        total_paid: 0,
        total_received: 0,
        final_amount: 0,
    });

    useEffect(() => {
        getAccount('All');
    }, []);

    const getAccount = (party_type) => {
        setPayType(party_type)
        setLoading(true);
        axios.post(API_URL.ADMIN_LEDGER_OUTSATANDING, { party_type : party_type }).then(res => {
            setAccountData(res.data.data);
            setOutstandingData(res.data.outstanding);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <span className='d-inline-block'>Outstanding</span>
                </div>
                <div className='card-body p-0'>
                    <div className="p-3 border-bottom text-center">
                        <div className="form-check form-check-inline">
                            <input name="group1" type="radio" value={'All'} checked={payType === "All"} onChange={(e) => getAccount(e.target.value)} id="inline-radio-1" className="form-check-input" />
                            <label title="All" htmlFor="inline-radio-1" className="form-check-label">All</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="group1" type="radio" value={'Seller'} checked={payType === "Seller"} onChange={(e) => getAccount(e.target.value)} id="inline-radio-2" className="form-check-input" />
                            <label title="Sales" htmlFor="inline-radio-2" className="form-check-label">Sales</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="group1" type="radio" value={'Buyer'} checked={payType === "Buyer"} onChange={(e) => getAccount(e.target.value)} id="inline-radio-3" className="form-check-input" />
                            <label title="Purchase" htmlFor="inline-radio-3" className="form-check-label">Purchase</label>
                        </div>
                    </div>
                    {
                        !isLoading && <div className="table-responsive">
                            <table className='table table-hover m-0'>
                                <thead className='thead'>
                                    <tr>
                                        <td>Party</td>
                                        <td>Paid / Sales</td>
                                        <td>Received / Purchase</td>
                                        <td>Total</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    { accountData.map((acc, k) => {
                                        return <tr key={k}>
                                                <td>{acc.party_name}</td>
                                                <td>{acc.paid.toFixed(2)}</td>
                                                <td>{acc.received.toFixed(2)}</td>
                                                <td className={(acc.total < 0) ? 'text-success': 'text-danger'}>{ (Math.abs(acc.total)).toFixed(2) } { (acc.total > 0) ? 'Dr': 'Cr' }</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                                <thead className='thead'>
                                    <tr>
                                        <th>Total</th>
                                        <th>{outstandingData.total_paid.toFixed(2)}</th>
                                        <th>{outstandingData.total_received.toFixed(2)}</th>
                                        <td className={(outstandingData.final_amount < 0) ? 'text-success': 'text-danger'}>{ (Math.abs(outstandingData.final_amount)).toFixed(2) } { (outstandingData.final_amount > 0) ? 'Dr': 'Cr' }</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    }
                </div>
            </div>
            {
                isLoading && <LoaderComponent mode='full' height={100} width={100}></LoaderComponent>
            }
        </div>
    )
}