import { React, useEffect, useState } from 'react';
import { API_URL } from '../../shared/constant';
import LoaderComponent from '../../shared/loader';
import axios from 'axios';
import Select from 'react-select'
import Form from 'react-bootstrap/Form';

export default function SearchLedgerByDay() {

    const [isLoading, setLoading] = useState(false);
    const [days, setDays] = useState('');
    const [accountData, setAccountData] = useState([]);
    const [partyData, setPartyData] = useState([]);
    const [firstCol, setFirstCol] = useState('');
    const [secondCol, setSecondCol] = useState('');
    const [thirdCol, setThirdCol] = useState('');
    const [aboveCol, setAboveCol] = useState('');
    const [partyId, setPartyId] = useState('');
    const [outstandingData, setOutstandingData] = useState({
        first: 0,
        second: 0,
        third: 0,
        above: 0,
    });

    const getParty = () => {
        axios.post(API_URL.ADMIN_PARTY_LIST, { is_seller : [0, 1]}).then(res => {
            let pt = res.data.data.map((row) => {
                return {
                    value: row.id,
                    label: row.name
                }
            })
            setPartyData(pt);
        })
    };

    const searchByDay = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_LEDGER_OUTSATANDING, { days : days, party_id: partyId}).then(res => {
            setAccountData(res.data.data);
            setOutstandingData(res.data.outstanding);
            // let pt = res.data.data.map((row) => {
            //     return {
            //         value: row.party_id,
            //         label: row.party_name
            //     }
            // })
            // setPartyData(pt);
            setFirstCol('0 - ' + days);
            setSecondCol(parseInt(days) + ' - ' + (parseInt(days) * 2));
            setThirdCol((parseInt(days) * 2) + ' - ' + (parseInt(days) * 3));
            setAboveCol((parseInt(days) * 3) + ' Above');
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    useEffect(() => {
        getParty();
    }, []);

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <span className='d-inline-block'>Day Wise</span>
                </div>
                <div className='card-body p-0'>
                    <div className='row p-3'>
                        <div className='col-md-4 col-sm-12 mb-2'>
                        <Select placeholder={'Select Party'} options={partyData} value={partyData.find(pt => pt.value == partyId)} onChange={(e) => setPartyId(e.value)} />
                        </div>
                        <div className='col-md-4 col-sm-12 mb-2'>
                            <input type={'text'} className="form-control input-sm" value={days} placeholder='Days' onChange={(e) => setDays(e.target.value)} />
                        </div>
                        <div className='col-md-4 col-sm-12 mb-2'>
                        <button type='button' className='btn btn-dark' disabled={!days || days < 1} onClick={() => searchByDay()}>Search</button>
                        </div>
                    </div>
                    {
                        (!isLoading && days > 0 && accountData.length) ? <div className="table-responsive">
                            <table className='table table-hover m-0'>
                                <thead className='thead'>
                                    <tr>
                                        <td>Party</td>
                                        <td>{firstCol}</td>
                                        <td>{secondCol}</td>
                                        <td>{thirdCol}</td>
                                        <td>{aboveCol}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    { accountData.map((acc, k) => {
                                        return <tr key={k} className={(partyId && partyId != acc.party_id) ? 'd-none': ''}>
                                                <td>{acc.party_name}</td>
                                                <td className={(acc.first < 0) ? 'text-success': ''}>{(Math.abs(acc.first)).toFixed(2)}</td>
                                                <td className={(acc.second < 0) ? 'text-success': ''}>{(Math.abs(acc.second)).toFixed(2)}</td>
                                                <td className={(acc.third < 0) ? 'text-success': ''}>{(Math.abs(acc.third)).toFixed(2)}</td>
                                                <td className={(acc.above < 0) ? 'text-success': ''}>{(Math.abs(acc.above)).toFixed(2)}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                                { (!partyId) &&
                                <thead className='thead'>
                                    <tr>
                                        <th>Total</th>
                                        <th className={(outstandingData.first < 0) ? 'text-success': ''}>{(Math.abs(outstandingData.first)).toFixed(2)}</th>
                                        <th className={(outstandingData.second < 0) ? 'text-success': ''}>{(Math.abs(outstandingData.second)).toFixed(2)}</th>
                                        <th className={(outstandingData.third < 0) ? 'text-success': ''}>{(Math.abs(outstandingData.third)).toFixed(2)}</th>
                                        <th className={(outstandingData.above < 0) ? 'text-success': ''}>{(Math.abs(outstandingData.above)).toFixed(2)}</th>
                                    </tr>
                                </thead> }
                            </table>
                        </div>: null
                    }
                </div>
            </div>
            {
                isLoading && <LoaderComponent mode='full' height={100} width={100}></LoaderComponent>
            }
        </div>
    )
}