import React from 'react';
import { ThreeDots, Watch } from 'react-loader-spinner'

const LoaderComponent = ({ height, width, type, style, mode = 'default' }) => {

    return (
        <React.Fragment>
            <div className={(mode == 'full') ? 'full-page-loader': 'default-loader'}>
                {!type &&
                    <div className='row'>
                        <div className='col-md-12 d-flex align-items-center justify-content-center' style={{ margin: 'auto', width: 100, display: 'flex' }}>
                            <ThreeDots color="#000000" height={height} width={width} />
                        </div>
                    </div>
                }
                {
                    type === 'watch' &&
                    <div style={style ? style : {
                        position: 'absolute',
                        right: '185px'
                    }}>
                        <Watch color="#00BFFF" height={height} width={width} />
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default LoaderComponent; 